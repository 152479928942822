import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useState,
} from 'react';
import { useForm, useFormState } from 'react-final-form';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { type PaymentIntent, type SetupIntent, type StripeError } from '@stripe/stripe-js';
import {
  PaymentElementTab,
  type StripeChekoutFormFields,
  StripePaymentElements,
  StripePaymentIntentStatus,
} from '@/components/paymentProviders/Stripe/typedefs';
import { useStripeValidation } from '@/components/paymentProviders/Stripe/StripeCheckoutForm/hooks/useStripeValidation';
import { EMPTY_CHECKOUT_FIELDS_ERROR, PINGBACK_WAITING_TIME } from '@/components/paymentProviders/Stripe/constants';
import { analyticsSDK } from '@/controllers/analytics';
import { type ProcessSubscriptionPaymentPricingOption, type ProcessSubscriptionPaymentSubscriptionPlan } from '@/components/platform/SubscriptionProduct/typedefs';
import { PaymentMethods } from '@/controllers/analytics/generated';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { LOCAL_STORAGE_KEYS } from '@/constants/localStorage';
import { SESSION_STORAGE_KEYS } from '@/constants/sessionStorage';
import {
  checkIfPlanIsFreeTrial,
} from '@/controllers/subscriptionPlans/subscriptionPlans.helpers/checkIfPlanIsFreeTrial';
import { getStripeValidationErrorCode } from '@/components/paymentProviders/Stripe/helpers';

type Props = {
  redirectUrl: string;
  setErrorMessage: Dispatch<SetStateAction<string | null>>;
  subscriptionPlan: ProcessSubscriptionPaymentSubscriptionPlan;
  pricingOption: ProcessSubscriptionPaymentPricingOption | null;
  clientSecret?: string;
  currentTab?: PaymentElementTab;
};

export const useStripeCheckout = ({
  redirectUrl,
  setErrorMessage,
  subscriptionPlan,
  pricingOption,
  clientSecret,
  currentTab = PaymentElementTab.Card,
}: Props) => {
  const [
    isFreeTrialFunnel,
  ] = useLocalStorage(
    LOCAL_STORAGE_KEYS.isFreeTrialFunnel,
    false,
  );
  const stripe = useStripe();
  const elements = useElements();
  const state = useFormState<StripeChekoutFormFields>();
  const [isLoading, setIsLoading] = useState(false);

  const form = useForm();

  const { values, errors } = state;

  const isFreeTrialPlan = checkIfPlanIsFreeTrial({
    subscriptionPlan,
    pricingOption,
  });

  const paymentElement = isFreeTrialPlan
    ? StripePaymentElements.Card
    : StripePaymentElements.Payment;

  const {
    isFormFilled: areStripeFieldsCompleted,
    error: validationError,
    isPristine,
    setError,
  } = useStripeValidation(paymentElement);

  const submit = useCallback(async (e: any) => {
    e.preventDefault();

    setErrorMessage(null);

    const isEmailInvalid = currentTab === PaymentElementTab.Card
      ? errors?.email
      : false;

    const submitResult = await elements?.submit();

    await analyticsSDK.subscriptionProduct.sendCheckoutPayClickedEvent({
      paymentMethod: PaymentMethods.Card,
      validationError: getStripeValidationErrorCode(
        submitResult ?? null,
        isEmailInvalid,
      ),
    });

    if (
      !stripe
      || !elements
      || isEmailInvalid
      || !areStripeFieldsCompleted
      || submitResult?.error
    ) {
      form.submit();

      if (
        isPristine
        || (!validationError && !areStripeFieldsCompleted)
      ) {
        setError(EMPTY_CHECKOUT_FIELDS_ERROR);
      }

      return;
    }

    setIsLoading(true);

    let result: PaymentIntent | SetupIntent | undefined;
    let error: StripeError | undefined;
    let email: string | undefined | null;

    const billingDetails = currentTab === PaymentElementTab.Card
      ? {
        email: values.email,
      }
      : {};

    if (!isFreeTrialPlan) {
      const paymentResult = await stripe.confirmPayment({
        elements,
        redirect: 'if_required',
        confirmParams: {
          return_url: redirectUrl,
          payment_method_data: {
            billing_details: billingDetails,
          },
        },
      });

      result = paymentResult.paymentIntent;
      error = paymentResult.error;
      email = error
        && paymentResult.error?.payment_method?.billing_details?.email;
    } else {
      const cardElement = elements?.getElement(CardElement);

      if (!cardElement || !clientSecret) {
        return;
      }

      const { paymentMethod, error: paymentMethodError } = await stripe
        .createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: billingDetails,
        });

      const setupResult = await stripe.confirmSetup({
        clientSecret,
        redirect: 'if_required',
        confirmParams: {
          return_url: redirectUrl,
          payment_method: paymentMethod?.id,
        },
      });

      result = setupResult.setupIntent;
      error = paymentMethodError || setupResult.error;
      email = error
        && setupResult.error?.payment_method?.billing_details?.email;
    }

    const status = result?.status;

    if (status === StripePaymentIntentStatus.Succeeded) {
      const {
        name,
        periodDuration,
        period,
        type,
      } = subscriptionPlan;

      analyticsSDK.subscriptionProduct.sendPurchaseCompletedEvent({
        paymentMethod: PaymentMethods.Card,
        pricingOptionSlug: pricingOption?.slug,
        pricingOptionName: pricingOption?.name || undefined,
        subscriptionPlanName: name,
        subscriptionPrice: (
          pricingOption?.renewalPrice ?? subscriptionPlan.salePrice
        ),
        subscriptionDuration: `${periodDuration}${period}`,
        subscriptionType: type,
        subscriptionPaymentType: (
          pricingOption?.paymentType ?? subscriptionPlan.paymentType
        ),
      });

      if (isFreeTrialFunnel) {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.isFreeTrialFunnel);
        sessionStorage.removeItem(
          SESSION_STORAGE_KEYS.currentFreeTrialPaywallDisplays,
        );
      }
    }

    const paymentError = error?.message;

    if (paymentError) {
      setErrorMessage(paymentError);

      analyticsSDK.subscriptionProduct.sendCheckoutErrorEvent({
        errorCode: error?.code || '',
        errorMessage: paymentError,
        emailError: email || '',
      });

      setIsLoading(false);
    }

    setTimeout(() => {
      if (paymentError) {
        return;
      }

      window.parent.location.href = redirectUrl;

      setIsLoading(false);
    }, PINGBACK_WAITING_TIME); // quick solution to prevent cases when pingback is not processed yet
  }, [
    elements,
    stripe,
    redirectUrl,
    values,
    errors,
    form,
    setErrorMessage,
    subscriptionPlan,
    pricingOption,
    isFreeTrialPlan,
    areStripeFieldsCompleted,
    clientSecret,
    isPristine,
    setError,
    validationError,
    isFreeTrialFunnel,
    currentTab,
  ]);

  return {
    submit,
    isLoading,
    setIsLoading,
    elements,
    isFormCompleted: areStripeFieldsCompleted && !errors?.email,
    validationError,
  };
};
