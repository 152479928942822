import { type StripeError } from '@stripe/stripe-js';
import {
  type PricingOptionTrialPeriod,
  type SubscriptionPeriod,
  type SubscriptionPlanPaymentType,
  type SubscriptionTrialPeriod,
} from '@/controllers/graphql/generated';

export interface StripeChekoutFormFields {
  email: string;
}

export interface SubscriptionDetails {
  pricePerPeriod: string;
  pricePeriod: SubscriptionPeriod;
  savings: string | null;
  defaultPrice: string;
  planTitle: string;
  discountAmount: string | null;
  discountTitle: string | null;
  totalPrice: string;
  subscriptionPeriod: SubscriptionPeriod;
  subscriptionPeriodDuration: number;
  paymentType: SubscriptionPlanPaymentType;
  rebillPrice: string;
  trialPeriod: SubscriptionTrialPeriod | PricingOptionTrialPeriod | null;
  trialPeriodDuration: number | null;
}

export enum StripePaymentIntentStatus {
  Canceled = 'canceled',
  Processing = 'processing',
  RequiresAction = 'requires_action',
  RequiresCapture = 'requires_capture',
  RequiresConfirmation = 'requires_confirmation',
  RequiresPaymentMethod = 'requires_payment_method',
  Succeeded = 'succeeded',
}

export enum StripeFormStep {
  Registration = 'registration',
  Payment = 'payment',
}

export enum StripePaymentElements {
  Payment = 'payment',
  Card = 'card',
}

export enum PaymentElementTab {
  Card = 'card',
  PayPal = 'paypal',
}

export enum StripePaymentMethodType {
  Card = 'card',
  Link = 'link',
  Blik = 'blik',
  PayPal = 'paypal',
}

export interface PaymentElementValidationError {
  error?: StripeError;
}
