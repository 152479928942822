import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import {
  type PaymentIntent,
  type SetupIntent,
  type StripeError,
  type StripeExpressCheckoutElementClickEvent,
  type StripeExpressCheckoutElementConfirmEvent,
  type StripeExpressCheckoutElementReadyEvent,
  type AvailablePaymentMethods,
} from '@stripe/stripe-js';
import { PINGBACK_WAITING_TIME, STRIPE_PAYMENT_METHODS_MAP } from '@/components/paymentProviders/Stripe/constants';
import { analyticsSDK } from '@/controllers/analytics';
import { StripePaymentIntentStatus } from '@/components/paymentProviders/Stripe/typedefs';
import {
  type ProcessSubscriptionPaymentPricingOption,
  type ProcessSubscriptionPaymentSubscriptionPlan,
} from '@/components/platform/SubscriptionProduct/typedefs';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { LOCAL_STORAGE_KEYS } from '@/constants/localStorage';
import { SESSION_STORAGE_KEYS } from '@/constants/sessionStorage';
import {
  checkIfPlanIsFreeTrial,
} from '@/controllers/subscriptionPlans/subscriptionPlans.helpers/checkIfPlanIsFreeTrial';

type Props = {
  clientSecret: string;
  redirectUrl: string;
  setErrorMessage: Dispatch<SetStateAction<string | null>>;
  subscriptionPlan: ProcessSubscriptionPaymentSubscriptionPlan;
  pricingOption: ProcessSubscriptionPaymentPricingOption | null;
  setAvailableMethods?: Dispatch<
    SetStateAction<AvailablePaymentMethods | null>
  >;
};

export const useStripeExpressCheckout = ({
  redirectUrl,
  setErrorMessage,
  subscriptionPlan,
  pricingOption,
  clientSecret,
  setAvailableMethods,
}: Props) => {
  const [
    isFreeTrialFunnel,
  ] = useLocalStorage(
    LOCAL_STORAGE_KEYS.isFreeTrialFunnel,
    false,
  );

  const [isMethodAvalable, setIsMethodAvalable] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const expressCheckout = elements?.getElement('expressCheckout');

  useEffect(() => {
    if (expressCheckout) {
      expressCheckout.on('click', (event: StripeExpressCheckoutElementClickEvent) => {
        analyticsSDK.subscriptionProduct.sendCheckoutPayClickedEvent({
          paymentMethod: STRIPE_PAYMENT_METHODS_MAP[event.expressPaymentType],
        });
      });
    }
  }, [expressCheckout]);

  const isFreeTrialPlan = checkIfPlanIsFreeTrial({
    subscriptionPlan,
    pricingOption,
  });

  const submit = useCallback(async (
    event: StripeExpressCheckoutElementConfirmEvent,
  ) => {
    if (!stripe || !elements) {
      return;
    }

    let result: PaymentIntent | SetupIntent | undefined;
    let error: StripeError | undefined;
    let email: string | undefined | null;

    if (!isFreeTrialPlan) {
      const paymentResult = await stripe.confirmPayment({
        elements,
        redirect: 'if_required',
        confirmParams: {
          return_url: redirectUrl,
        },
        clientSecret,
      });

      result = paymentResult.paymentIntent;
      error = paymentResult.error;
      email = error
        && paymentResult.error?.payment_method?.billing_details?.email;
    } else {
      const setupResult = await stripe.confirmSetup({
        elements,
        redirect: 'if_required',
        confirmParams: {
          return_url: redirectUrl,
        },
      });

      result = setupResult.setupIntent;
      error = setupResult.error;

      email = error
        && setupResult.error?.payment_method?.billing_details?.email;
    }

    const status = result?.status;

    if (status === StripePaymentIntentStatus.Succeeded) {
      const {
        name,
        periodDuration,
        period,
        type,
      } = subscriptionPlan;

      analyticsSDK.subscriptionProduct.sendPurchaseCompletedEvent({
        paymentMethod: STRIPE_PAYMENT_METHODS_MAP[event.expressPaymentType],
        pricingOptionSlug: pricingOption?.slug,
        pricingOptionName: pricingOption?.name || undefined,
        subscriptionPlanName: name,
        subscriptionPrice: (
          pricingOption?.renewalPrice ?? subscriptionPlan.salePrice
        ),
        subscriptionDuration: `${periodDuration}${period}`,
        subscriptionType: type,
        subscriptionPaymentType: (
          pricingOption?.paymentType ?? subscriptionPlan.paymentType
        ),
      });

      if (isFreeTrialFunnel) {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.isFreeTrialFunnel);
        sessionStorage.removeItem(
          SESSION_STORAGE_KEYS.currentFreeTrialPaywallDisplays,
        );
      }
    }

    if (error?.message) {
      setErrorMessage(error.message);

      analyticsSDK.subscriptionProduct.sendCheckoutErrorEvent({
        errorCode: error?.code || '',
        errorMessage: error.message,
        emailError: email || '',
      });
    }

    setTimeout(() => {
      if (error) {
        return;
      }

      window.parent.location.href = redirectUrl;
    }, PINGBACK_WAITING_TIME); // quick solution to prevent cases when pingback is not processed yet
  }, [
    stripe,
    elements,
    isFreeTrialPlan,
    redirectUrl,
    subscriptionPlan,
    pricingOption?.slug,
    pricingOption?.name,
    pricingOption?.renewalPrice,
    pricingOption?.paymentType,
    isFreeTrialFunnel,
    setErrorMessage,
    clientSecret,
  ]);

  const handleExpressCheckoutClick = useCallback((
    event: StripeExpressCheckoutElementClickEvent,
  ) => {
    event.resolve({
      emailRequired: true,
    });
  }, []);

  const handleExpressCheckoutReady = useCallback((
    event: StripeExpressCheckoutElementReadyEvent,
  ) => {
    const isAnyMethodAvailable = Boolean(
      event.availablePaymentMethods?.googlePay
      || event.availablePaymentMethods?.applePay,
    );

    setIsMethodAvalable(isAnyMethodAvailable);
    setAvailableMethods?.(
      event.availablePaymentMethods || {
        applePay: false,
        googlePay: false,
      },
    );
  }, [
    setAvailableMethods,
  ]);

  return {
    submit,
    handleExpressCheckoutClick,
    handleExpressCheckoutReady,
    isMethodAvalable,
  };
};
