import {
  type Dispatch,
  type FC,
  type SetStateAction,
  useCallback,
  useMemo,
} from 'react';
import { ExpressCheckoutElement } from '@stripe/react-stripe-js';
import { type AvailablePaymentMethods, type StripeExpressCheckoutElementClickEvent } from '@stripe/stripe-js';
import { cn } from '@/lib/classNames';
import styles
  from '@/components/paymentProviders/Stripe/StripeCheckoutForm/components/ExpressCheckoutForm/StripeExpressCheckoutForm.module.scss';
import { I18N_CODES } from '@/lib/constants/general';
import { useRouter, useTranslation } from '@/middleware/i18n';
import {
  useStripeExpressCheckout,
} from '@/components/paymentProviders/Stripe/StripeCheckoutForm/hooks/useStripeExpressCheckout';
import { typography } from '@/components/ui/typography';
import {
  getExpressCheckoutOptions,
} from '@/components/paymentProviders/Stripe/helpers';
import {
  type ProcessSubscriptionPaymentPricingOption,
  type ProcessSubscriptionPaymentSubscriptionPlan,
} from '@/components/platform/SubscriptionProduct/typedefs';
import { useRouterQuery } from '@/hooks/useRouterQuery';
import { analyticsSDK } from '@/controllers/analytics';
import { ROUTES } from '@/controllers/router/router.contants';
import {
  checkIfPlanIsFreeTrial,
} from '@/controllers/subscriptionPlans/subscriptionPlans.helpers/checkIfPlanIsFreeTrial';
import { STRIPE_PAYMENT_METHODS_MAP } from '@/components/paymentProviders/Stripe/constants';

interface Props {
  redirectUrl: string;
  setErrorMessage: Dispatch<SetStateAction<string | null>>;
  subscriptionPlan: ProcessSubscriptionPaymentSubscriptionPlan;
  pricingOption: ProcessSubscriptionPaymentPricingOption | null;
  hideDivider?: boolean;
  clientSecret: string;
  setAvailableMethods?: Dispatch<
    SetStateAction<AvailablePaymentMethods | null>
  >;
}

export const StripeExpressCheckout: FC<Props> = ({
  redirectUrl,
  setErrorMessage,
  subscriptionPlan,
  pricingOption,
  hideDivider = false,
  clientSecret,
  setAvailableMethods,
}) => {
  const { t } = useTranslation(I18N_CODES.payment);
  const router = useRouter();

  const { isPaymentDisabled } = useRouterQuery<{
    isPaymentDisabled: boolean;
  }>();

  const isFreeTrialPlan = checkIfPlanIsFreeTrial({
    subscriptionPlan,
    pricingOption,
  });

  const options = useMemo(() => (
    getExpressCheckoutOptions(isFreeTrialPlan)
  ), [isFreeTrialPlan]);

  const {
    isMethodAvalable,
    submit,
    handleExpressCheckoutClick,
    handleExpressCheckoutReady,
  } = useStripeExpressCheckout({
    redirectUrl,
    setErrorMessage,
    subscriptionPlan,
    pricingOption,
    setAvailableMethods,
    clientSecret,
  });

  const clickHandlerMock = useCallback(async (
    event: StripeExpressCheckoutElementClickEvent,
  ) => {
    await analyticsSDK.subscriptionProduct.sendCheckoutPayClickedEvent({
      paymentMethod: STRIPE_PAYMENT_METHODS_MAP[event.expressPaymentType],
    });

    await router.push(ROUTES.user.paymentError);
  }, [router]);

  const clickHandler = useMemo(() => {
    if (isPaymentDisabled) {
      return clickHandlerMock;
    }

    return handleExpressCheckoutClick;
  }, [isPaymentDisabled, handleExpressCheckoutClick, clickHandlerMock]);

  return (
    <>
      <ExpressCheckoutElement
        options={options}
        onConfirm={submit}
        onClick={clickHandler}
        onReady={handleExpressCheckoutReady}
      />

      {isMethodAvalable && !hideDivider && (
        <div className={styles.payWithCardBlock}>
          <div className={cn(styles.divider, typography.platformTextSmall)} />
          {isFreeTrialPlan
            ? t(`${I18N_CODES.payment}:stripe.use_card`)
            : t(`${I18N_CODES.payment}:stripe.pay_with_card`)}
          <div className={styles.divider} />
        </div>
      )}
    </>
  );
};
