import { periodMultiplicationCoefficients } from '@/components/platform/SubscriptionProduct/components/SubscriptionPlan/constants';
import { type SubscriptionPlanPriceAndPeriodData } from '@/components/platform/SubscriptionProduct/components/SubscriptionPlan/typedefs';
import { SubscriptionPeriod } from '@/controllers/graphql/generated';

const correctionCoeficient = 100;

export const getPricePerPeriod = (
  plan: SubscriptionPlanPriceAndPeriodData,
  requestedPeriod: SubscriptionPeriod,
): number => {
  const {
    period,
    salePrice,
    periodDuration,
    trialPeriodDuration,
  } = plan;

  if (period === SubscriptionPeriod.Week
    && trialPeriodDuration === 1
  ) {
    return salePrice;
  }

  const requestedPeriodCoefficient = (
    periodMultiplicationCoefficients[requestedPeriod][period]
  );

  const pricePerSingleSubscipionPeriod = salePrice / periodDuration; // e.g. price per month for x-months plan

  const requestedPeriodPrice = ( // e.g. price per day for x-months plan
    pricePerSingleSubscipionPeriod
      * requestedPeriodCoefficient
      * correctionCoeficient
  );

  return Math.ceil(requestedPeriodPrice) / correctionCoeficient;
};
