import { type BadgeType } from '@/components/platform/Payment/TransactionStatusBadge/typography';
import {
  type SubscriptionPeriod,
  type SubscriptionTrialPeriod,
  type SubscriptionType,
  type SubscriptionPlanPaymentType,
  type PricingOptionPaymentType, type PricingOptionTrialPeriod,
} from '@/controllers/graphql/generated';
import { type PaymentWithCurrencyFragment } from '@/components/platform/Payment/graphql/generated/paymentWithCurrency.fragment.generated';
import { type SubscriptionPlanBaseFragment } from '@/components/platform/SubscriptionPlans/graphql/generated/subscriptionPlanBase.fragment.generated';
import { type CurrencyBaseFragment } from '@/controllers/currency/graphql/generated/currencyBase.fragment.generated';
import { type UserCardBaseFragment } from '@/controllers/user/graphql/generated/UserCardBase.fragment.generated';
import { type UserCardPaymentProviderFragment } from '@/controllers/user/graphql/generated/UserCardPaymentProvider.fragment.generated';

export type SubscriptionProductPlanType = Extract<
  SubscriptionType,
  SubscriptionType.SubscriptionProductMonthly
  | SubscriptionType.SubscriptionProductWeekly
>;

export enum SubscriptionProductSubscriptionStatus {
  Active = 'active',
  Pending = 'pending',
  Canceled = 'cancelled',
}

export interface SubscriptionProductSubscriptionData {
  id: number;
  payments: PaymentWithCurrencyFragment[];
  subscriptionPlan: SubscriptionPlanBaseFragment;
  subscriptionType: SubscriptionProductPlanType;
  status: SubscriptionProductSubscriptionStatus;
  price: string;
  period: SubscriptionPeriod;
  acceptedPaymentsCount: number;
  cardData: (UserCardBaseFragment & UserCardPaymentProviderFragment) | null;
  nextPaymentDate: Date | null;
  nextPaymentDateString: string | null;
}

export type ProcessSubscriptionPaymentSubscriptionPlan = {
  id: number;
  uuid: string;
  trialPrice?: number | null;
  defaultPrice: number;
  salePrice: number;
  trialActive: boolean;
  periodDuration: number;
  period: SubscriptionPeriod;
  trialPeriodDuration?: number | null;
  trialPeriod?: SubscriptionTrialPeriod | null;
  name: string;
  userFriendlyName: string;
  type: SubscriptionType;
  currency?: CurrencyBaseFragment | null;
  paymentType: SubscriptionPlanPaymentType;
};

export interface ProcessSubscriptionPaymentPricingOption {
  id: number;
  slug: string;
  name?: string | null;
  uuid: string;
  paymentType: PricingOptionPaymentType;
  referencePrice: number;
  salePrice: number;
  renewalPrice: number;
  currency: CurrencyBaseFragment;
  trialPeriod?: PricingOptionTrialPeriod | null;
  trialPeriodDuration?: number | null;
  isDefault: boolean;
}

export enum PaymentIssueComponent {
  None = 'None',
  CurrentPlan = 'CurrentPlan',
  PaymentMethod = 'PaymentMethod',
}

export enum StripePaymentErrorMessages {
  InsufficientFunds = 'Your card has insufficient funds.',
  GenericDecline = 'Your card has been declined.',
  CardExpired = 'Your card has expired.',
  IncorrectCVC = `Your card's security code is incorrect.`,
  ProcessingError = 'An error occurred while processing your card. Try again in a little bit.',
  VelocityLimit = 'Your card was declined for making repeated attempts too frequently.',
}

export interface BillingHistoryPayment {
  id: number;
  amount: number;
  currency: string;
  status: BadgeType;
  createdAt: Date;
  description: string;
  errorMessage: string | null;
}

export enum AnonymousCheckoutMode {
  Express = 'Express',
  Card = 'Card',
}
