import getConfig from 'next/config';
import { useMemo } from 'react';
import { loadStripe, type StripeElementLocale, type StripeElementsOptions } from '@stripe/stripe-js';
import { STRIPE_ELEMENTS_FONTS, getStripeElementsAppearenceOptions } from '@/components/paymentProviders/Stripe/constants';
import { useTranslation } from '@/middleware/i18n';
import { usePlatformTheme } from '@/components/common/ThemeContext';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';

type Props = {
  clientSecret: string;
  paymentMethodCreation?: string;
};

export const useStripeElementData = ({
  clientSecret,
  paymentMethodCreation,
}: Props) => {
  const { i18n } = useTranslation();

  const { isDarkMode } = usePlatformTheme();
  const appearance = getStripeElementsAppearenceOptions(isDarkMode);

  const elementsOptions: StripeElementsOptions = useMemo(() => ({
    clientSecret,
    appearance,
    fonts: STRIPE_ELEMENTS_FONTS,
    paymentMethodCreation,
  }), [
    appearance,
    clientSecret,
    paymentMethodCreation,
  ]);

  const { isKnowelyProduct } = useSubDomainContext();

  const stripePromise = useMemo(() => {
    const { publicRuntimeConfig } = getConfig();

    const publicKey = isKnowelyProduct
      ? publicRuntimeConfig.STRIPE_PUBLIC_KEY_SUB
      : publicRuntimeConfig.STRIPE_PUBLIC_KEY;

    return loadStripe(
      publicKey,
      {
        locale: i18n.language as StripeElementLocale,
      },
    );
  }, [i18n.language, isKnowelyProduct]);

  return {
    stripePromise,
    elementsOptions,
  };
};
