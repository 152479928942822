import { useMemo } from 'react';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { type SubscriptionDetails } from '@/components/paymentProviders/Stripe/typedefs';
import {
  type ProcessSubscriptionPaymentPricingOption,
  type ProcessSubscriptionPaymentSubscriptionPlan,
} from '@/components/platform/SubscriptionProduct/typedefs';
import { subtractWithFloatingPoint } from '@/lib/helpers/functional';
import { useNumberFormatter } from '@/controllers/i18n/i18n.hooks/useNumberFormatter';
import {
  PricingOptionPaymentType,
  Currencies,
  SubscriptionPeriod,
  SubscriptionPlanPaymentType,
} from '@/controllers/graphql/generated';
import { getPricePerPeriod } from '@/components/platform/SubscriptionProduct/components/SubscriptionPlan/utils/getPricePerPeriod';
import { getComparisonBasedSavings, getDiscountBlockTitle } from '@/components/paymentProviders/Stripe/helpers';

export const useSubscriptionDetails = (
  subscriptionPlan: ProcessSubscriptionPaymentSubscriptionPlan | null,
  pricingOption: ProcessSubscriptionPaymentPricingOption | null,
): SubscriptionDetails | null => {
  const { t, i18n } = useTranslation([
    I18N_CODES.subscriptions,
    I18N_CODES.payment,
  ]);

  const formatter = useNumberFormatter({
    style: 'currency',
    currency: subscriptionPlan?.currency?.code ?? Currencies.Usd,
  });

  return useMemo(() => {
    if (!subscriptionPlan) {
      return null;
    }

    const { userFriendlyName } = subscriptionPlan;

    const referencePrice = pricingOption?.referencePrice
      ?? subscriptionPlan.defaultPrice;

    const isIntroOffer = (
      pricingOption
        ? [
          PricingOptionPaymentType.IntroOffer,
          PricingOptionPaymentType.Trial,
        ].includes(pricingOption.paymentType)
        // eslint-disable-next-line max-len
        : subscriptionPlan.paymentType === SubscriptionPlanPaymentType.IntroOffer
    );

    const planSalePrice = (
      isIntroOffer && typeof subscriptionPlan.trialPrice === 'number'
    )
      ? subscriptionPlan.trialPrice
      : subscriptionPlan.salePrice;

    const salePrice = pricingOption?.salePrice
      ?? planSalePrice;

    const renewalPrice = pricingOption?.renewalPrice
      ?? subscriptionPlan.salePrice;

    const currencyCode = pricingOption?.currency.code
      ?? subscriptionPlan.currency?.code;

    if (!currencyCode) {
      return null;
    }

    const priceDifference = Math.abs(
      isIntroOffer
        ? subtractWithFloatingPoint(salePrice, renewalPrice)
        : subtractWithFloatingPoint(salePrice, referencePrice),
    );

    const priceDiscount = priceDifference > 0
      ? priceDifference
      : 0;

    const discount = isIntroOffer
      ? salePrice
      : priceDiscount;

    const discountAmount = discount && discount > 0
      ? `${discount} ${currencyCode}`
      : null;

    const discountTitle = getDiscountBlockTitle({
      subscriptionPlan, t, pricingOption,
    });

    const total = salePrice;

    const initialPrice = referencePrice;

    const {
      period,
      periodDuration,
    } = subscriptionPlan;

    const rebillPrice = salePrice;

    const checkoutPricePeriod = period === SubscriptionPeriod.Week
      ? SubscriptionPeriod.Week
      : SubscriptionPeriod.Month;

    const pricePerPeriod = getPricePerPeriod(
      {
        salePrice,
        periodDuration,
        trialPeriodDuration: pricingOption?.trialPeriodDuration
          ?? subscriptionPlan.trialPeriodDuration,
        period,
      },
      checkoutPricePeriod,
    );

    const savings = getComparisonBasedSavings(
      t,
      i18n,
      subscriptionPlan.name,
      pricingOption?.slug,
      period,
    );

    return {
      pricePerPeriod: formatter.format(pricePerPeriod),
      pricePeriod: checkoutPricePeriod,
      defaultPrice: formatter.format(initialPrice),
      savings: savings
        ? `${savings}%`
        : null,
      planTitle: userFriendlyName,
      discountAmount,
      discountTitle: discountAmount && discountTitle,
      totalPrice: formatter.format(Number(total)),
      rebillPrice: formatter.format(rebillPrice),
      subscriptionPeriod: subscriptionPlan.period,
      subscriptionPeriodDuration: subscriptionPlan.periodDuration,
      trialPeriodDuration: (
        pricingOption?.trialPeriodDuration
        ?? subscriptionPlan.trialPeriodDuration
      )
        || null,
      trialPeriod: (
        pricingOption?.trialPeriod ?? subscriptionPlan.trialPeriod
      ) || null,
      paymentType: subscriptionPlan.paymentType,
    };
  }, [subscriptionPlan, pricingOption, t, i18n, formatter]);
};
